// React
import { ReactElement, useEffect, useRef, useState } from 'react';
// Modules
import classNames from 'classnames/bind';
import fitty from 'fitty/dist/fitty.min.js';
// Design System
import { Font } from '@utmb/design-system/text/font';
import { Image } from '@utmb/design-system/image/Image';
import { Slides, SlidesItem } from '@utmb/design-system/slides/Slides';
// Interfaces
import EventView from '@utmb/types/view/EventView';
import { Slice } from '@utmb/types/slice-handler';
import { CloudinaryFiltersEnum } from '@utmb/types/cloudinary-transform.enum';
import { SliceEventsEventsUpcomingView } from '@utmb/types/index';
// Features
import { EventCard } from '@utmb/features/event-card/EventCard';
// Utils
import { pIdToCloudinary } from '@utmb/utils/cloudinary';
// Styles
import styles from './upcoming-events.module.scss';

const cx = classNames.bind(styles);

export const UpcomingEvents = (props: Slice<SliceEventsEventsUpcomingView>): ReactElement => {
    const { background: image, events, id, prefix, title } = props;

    const defaultBgColor = '#00dbff';
    const defaultBg = { image, color: defaultBgColor };
    const [background, setBackground] = useState(defaultBg);

    const titleRef = useRef(null);

    useEffect(() => {
        if (titleRef?.current) {
            fitty(titleRef.current, { maxSize: 200, multiLine: false });
        }
    });

    const showSliderDotsCSS = cx(styles.dots, {
        hide_all_bp: events?.length === 1,
        hide_dots_md: events?.length === 2,
        hide_dots_xl: events?.length === 3,
    });

    return (
        <section id={id} className={styles.root}>
            <Image
                objectFit="cover"
                className={styles.bg_img}
                image={background.image}
                objectPosition="center center"
                addFilters={{ [CloudinaryFiltersEnum.COLORIZE_COLOR]: background.color }}
            />
            <div className={`container ${styles.content_container}`}>
                {(prefix || title) && (
                    <div className={styles.title_wrapper}>
                        {prefix && (
                            <Font.FuturaMedium desktop="32" className={styles.text_01}>
                                {prefix}
                            </Font.FuturaMedium>
                        )}
                        {title && (
                            <span ref={titleRef} className={styles.text_02}>
                                {title}
                            </span>
                        )}
                    </div>
                )}
                <Slides
                    className={`row $ ${styles.slider_container}`}
                    carouselOptions={{
                        containScroll: 'trimSnaps',
                        align: 'center',
                        draggable: true,
                        loop: false,
                    }}
                    isDarkPagination
                    dotsClassName={showSliderDotsCSS}
                    pagination={true}
                >
                    {() => (
                        <>
                            {events.map((event: EventView, index: number) => (
                                <SlidesItem key={index} className={styles.slide_item_container}>
                                    <div
                                        onMouseEnter={() =>
                                            setBackground({
                                                image: pIdToCloudinary(event.picture, event.title),
                                                color: event.primaryColor || defaultBgColor,
                                            })
                                        }
                                        onMouseLeave={() => setBackground(defaultBg)}
                                    >
                                        <EventCard event={event} />
                                    </div>
                                </SlidesItem>
                            ))}
                        </>
                    )}
                </Slides>
            </div>
        </section>
    );
};
